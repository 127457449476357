import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Hero from "../../components/Hero"
import GalleryAndLightBox from "../../components/GalleryAndLightBox"
import Layout from "../../components/Layout"
import PackagesSection from "./components/PackagesSection"
import Cta from "./components/Cta"
import FormSection from "./components/FormSection"

const Senior = () => {
  const query = useStaticQuery(graphql`
    query {
      contentfulPageData(slug: { eq: "senior" }) {
        id
        title
        subtitle
        mainContent {
          mainContent
        }
        pageHeader {
          file {
            url
          }
        }
        otherFiles {
          fixed(width: 480, height: 480, toFormat: WEBP) {
            src
          }
          fluid(quality: 100, toFormat: AUTO) {
            src
          }
        }
        ctaHeading
        ctaText
        ctaLink
      }

      allContentfulServicePackage(
        filter: { category: { eq: "senior-portrait" } }
      ) {
        nodes {
          id
          title
          subtitle
          image {
            fluid(quality: 100, toFormat: WEBP) {
              src
            }
          }
          packageSlots
          price
          features {
            raw
          }
        }
      }
    }
  `)
  const {
    contentfulPageData: data,
    allContentfulServicePackage: { nodes: packages },
  } = query

  let image = {
    file: {
      url: "/img/featured/banner.jpg",
    },
    title: "A title",
  }

  let content =
    "Hi there! Tayo here. I’m currently a Graduate Student at Virginia Tech majoring in Aerospace and Ocean Engineering. I have been doing senior portraits for the past 4 years and I have grown and developed my style. These photos would be something you look back at 40 years from now. They require a lot of trust and I would love to help you create these memories. I offer group and individual sessions to seniors. These include 3 locations and limited time depending on the package. These are customised to suit you just how you like it."

  let pageImages = []

  // SHape the data
  if (data && data.id) {
    image = {
      file: {
        url: data.pageHeader.file.url,
      },
      title: data.title,
    }
    content = data.mainContent.mainContent
    pageImages = data?.otherFiles?.map((img, i) => ({
      id: i,
      thumbnail: img.fixed.src,
      mainSrc: img.fluid.src,
      title: img.title,
    }))
  }

  // React Runtime concerns
  const [selectedPackage, setSelectedPackage] = React.useState(null)
  const [images, setImages] = React.useState(pageImages)

  const selectPackage = (title, price, packageSlots) => {
    const service = {
      title,
      price,
      packageSlots,
    }
    setSelectedPackage(service)
  }

  return (
    <Layout>
      <Hero title={data.title} heroImage={image} />
      <Cta content={content} />
      {(images || images.length) && (
        <GalleryAndLightBox pictures={images} content={content} styling="p0" />
      )}
      <PackagesSection packages={packages} selectPackage={selectPackage} />
      <FormSection
        selectedPackage={selectedPackage}
        availablePackages={packages}
        paymentLink={data.ctaLink}
        ctaHeading={data.ctaHeading}
        ctaText={data.ctaText}
      />
    </Layout>
  )
}

export default Senior
